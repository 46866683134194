import { Col, Form, Input, message, Modal, Space, Typography } from 'antd';
import { MessageType } from 'antd/lib/message';
import { FormLabel } from 'components/UI/FormLabel';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { EmailPayload } from 'models/Application';
import { ContactModel, NotificationPayload, TriggerModel } from 'models/NotificationType';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { editAssignedCustomersByAllTypesEmail } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';
import * as yup from 'yup';

interface Props {
  // isEventTwo: boolean;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
  triggerName: string;
  contact: EmailPayload;
}
export const contactPayload: yup.SchemaOf<ContactModel> = yup.object({
  description: yup.string().label('Description').required(),
  emailAddress: yup.string().label('Email Address').email('Please enter a valid email address'),
  cellPhoneNumber: yup.string().label('Cell Phone Number'),
  enableSmsNotifications: yup.boolean()
});

export const EditContactModal: FC<Props> = ({ isOpen, setIsOpen, index, triggerName, contact }) => {
  const { values: formValues } = useFormikContext<NotificationPayload>();
  const [{ value: triggerContactVal }, b, { setValue: setTriggerContactVal }] = useField<ContactModel>(
    `triggers[${formValues.triggers.findIndex((trigger) => trigger.triggerName?.toLocaleLowerCase() === triggerName.toLocaleLowerCase())}].emailRecipients[${index}]`
  );
  const [{ value: triggerVals }, f, c] = useField<TriggerModel>(
    `triggers[${formValues.triggers.findIndex((trigger) => trigger.triggerName?.toLocaleLowerCase() === triggerName.toLocaleLowerCase())}]`
  );

  const dispatch = useDispatch();

  const { assignedCustomersByAllTypesEmail } = useAppSelector((state) => state.app);

  const idx = assignedCustomersByAllTypesEmail.findIndex((val) => val.emailAddress === contact.emailAddress);

  const [formState, setFormState] = useState({
    description: assignedCustomersByAllTypesEmail[idx]?.description,
    emailAddress: assignedCustomersByAllTypesEmail[idx]?.emailAddress
  });

  const editFormik = useFormik<EmailPayload>({
    enableReinitialize: true,
    initialValues: {
      description: formState.description,
      emailAddress: formState.emailAddress,
      triggerName: triggerName
    },
    validationSchema: contactPayload,
    onSubmit: async (values): Promise<void | MessageType> => {
      if (!values.emailAddress) return message.error('You must enter an email or phone number');

      dispatch(editAssignedCustomersByAllTypesEmail(values as any));

      message.success('Contact updated');
      setIsOpen(false);
      editFormik.resetForm();
    }
  });

  const handleCancel = (): void => {
    setIsOpen(false);
    editFormik.resetForm();
  };

  return (
    <>
      <Modal closable={false} destroyOnClose okText="Submit" width={450} onOk={(): Promise<void> => editFormik.submitForm()} onCancel={handleCancel} open={isOpen}>
        <FormikProvider value={editFormik}>
          <Col span={24} style={{ background: '#18a79984', borderRadius: 10, marginBottom: 10 }}>
            <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 20, marginBottom: 0, padding: 10, marginLeft: 10 }}>
              Contact Info
            </Typography.Title>
          </Col>
          <Col style={{ margin: '20px 10px' }}>
            <Form layout="vertical">
              <Space style={{ width: '100%' }} direction="vertical" size={10}>
                <FormLabel label="Description" />
                <Input value={formState.description} onChange={(e) => setFormState({ ...formState, description: e.target.value })} />
                <FormLabel label="Email Address" />
                <Input value={formState.emailAddress} onChange={(e) => setFormState({ ...formState, emailAddress: e.target.value })} />
              </Space>
            </Form>
          </Col>
        </FormikProvider>
      </Modal>
    </>
  );
};
