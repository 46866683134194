import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReleasedProduct } from 'models/ReleasedProduct';

interface InitialState {
  selectedProducts: ReleasedProduct[];
  bulkSearchedProducts: ReleasedProduct[];
}

const initialState: InitialState = {
  selectedProducts: [],
  bulkSearchedProducts: []
};

export const productSearchSlice = createSlice({
  name: 'productSearch',
  initialState,
  reducers: {
    setSelectedProducts: (state, { payload }: PayloadAction<ReleasedProduct[]>) => {
      state.selectedProducts = payload;
    },
    addSelectedProduct: (state, { payload }: PayloadAction<ReleasedProduct>) => {
      state.selectedProducts = [...state.selectedProducts, payload];
    },
    removeSelectedProduct: (state, { payload }: PayloadAction<ReleasedProduct>) => {
      state.selectedProducts = state.selectedProducts.filter((item) => item.productNumber !== payload.productNumber);
    },
    addBulkSearchedProduct: (state, { payload }: PayloadAction<ReleasedProduct>) => {
      state.bulkSearchedProducts = [...state.bulkSearchedProducts, payload];
    }
  }
});

export const { addBulkSearchedProduct, setSelectedProducts, addSelectedProduct, removeSelectedProduct } = productSearchSlice.actions;
