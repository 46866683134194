import * as yup from 'yup';

export interface Contact {
  name: string;
  phone: string;
  email: string;
}

export enum ContactLabels {
  name = 'Full Name',
  phone = 'Phone',
  email = 'Email'
}

export const contactSchema: yup.SchemaOf<Contact> = yup.object({
  name: yup.string().label(ContactLabels.name).typeError('${path} is required').required(),
  phone: yup.string().label(ContactLabels.phone).typeError('${path} is required').required(),
  email: yup.string().label(ContactLabels.email).typeError('${path} is required').required()
});
