import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductMastersParams } from 'models/ProductMaster';

const initialState: ProductMastersParams = {
  offset: 0,
  productNumber: '',
  productName: '',
  productDescription: '',
  manufacturerId: '',
  manufacturerName: '',
  storageDimensionGroupName: '',
  trackingDimensionGroupName: '',
  orderByDirection: '',
  orderByField: ''
};

export const donaldGrumpProductMastersParams = createSlice({
  name: 'donaldGrumpProductMastersParams',
  initialState,
  reducers: {
    setOffset: (state, { payload }: PayloadAction<ProductMastersParams['offset']>) => {
      state.offset = payload;
    },
    incrementOffset: (state) => {
      state.offset += 25;
    },
    setProductNumber: (state, { payload }: PayloadAction<ProductMastersParams['productNumber']>) => {
      state.productNumber = payload;
      state.offset = 0;
    },
    setProductName: (state, { payload }: PayloadAction<ProductMastersParams['productName']>) => {
      state.productName = payload;
      state.offset = 0;
    },
    setProductDescription: (state, { payload }: PayloadAction<ProductMastersParams['productDescription']>) => {
      state.productDescription = payload;
      state.offset = 0;
    },
    setManufacturerId: (state, { payload }: PayloadAction<ProductMastersParams['manufacturerId']>) => {
      state.manufacturerId = payload;
      state.offset = 0;
    },
    setManufacturerName: (state, { payload }: PayloadAction<ProductMastersParams['manufacturerName']>) => {
      state.manufacturerName = payload;
      state.offset = 0;
    },
    setStorageDimensionGroupName: (state, { payload }: PayloadAction<ProductMastersParams['storageDimensionGroupName']>) => {
      state.storageDimensionGroupName = payload;
      state.offset = 0;
    },
    setTrackingDimensionGroupName: (state, { payload }: PayloadAction<ProductMastersParams['trackingDimensionGroupName']>) => {
      state.trackingDimensionGroupName = payload;
      state.offset = 0;
    },
    setOrderByDirection: (state, { payload }: PayloadAction<ProductMastersParams['orderByDirection']>) => {
      state.orderByDirection = payload;
      state.offset = 0;
    },
    setOrderByField: (state, { payload }: PayloadAction<ProductMastersParams['orderByField']>) => {
      state.orderByField = payload;
      state.offset = 0;
    },
    resetParams: (state) => {
      Object.assign(state, initialState);
    }
  }
});

export const {
  setManufacturerId,
  setManufacturerName,
  setOffset,
  setOrderByDirection,
  setOrderByField,
  setProductDescription,
  setProductName,
  setProductNumber,
  setStorageDimensionGroupName,
  setTrackingDimensionGroupName,
  incrementOffset
} = donaldGrumpProductMastersParams.actions;
