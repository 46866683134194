import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetBusinessesParams } from 'models/Business';

const initialState: GetBusinessesParams = {
  includeDeletedData: undefined,
  includeInactiveData: undefined,
  returnDeletedBusinessesOnly: undefined,
  orderByDirection: undefined,
  orderByField: undefined,
  businessNameContains: undefined,
  businessDataAreaId: undefined,
  includeDivisions: true,
  includeInactiveDivisionData: undefined,
  includeDeletedDivisionData: undefined,
  returnDeletedDivisionsOnly: undefined,
  orderDivisionByDirection: undefined,
  orderDivisionByField: undefined,
  divisionNameContains: undefined
};

export const abbyCadabbyParams = createSlice({
  name: 'abbyCadabbyParams',
  initialState,
  reducers: {
    setIncludeDeletedData: (state, { payload }: PayloadAction<GetBusinessesParams['includeDeletedData']>) => {
      state.includeDeletedData = payload;
    },
    setIncludeInactiveData: (state, { payload }: PayloadAction<GetBusinessesParams['includeInactiveData']>) => {
      state.includeInactiveData = payload;
    },
    setReturnDeletedBusinessesOnly: (state, { payload }: PayloadAction<GetBusinessesParams['returnDeletedBusinessesOnly']>) => {
      state.returnDeletedBusinessesOnly = payload;
    },
    setOrderByDirection: (state, { payload }: PayloadAction<GetBusinessesParams['orderByDirection']>) => {
      state.orderByDirection = payload;
    },
    SetOrderByField: (state, { payload }: PayloadAction<GetBusinessesParams['orderByField']>) => {
      state.orderByField = payload;
    },
    setBusinessNameContains: (state, { payload }: PayloadAction<GetBusinessesParams['businessNameContains']>) => {
      state.businessNameContains = payload;
    },
    setBusinessDataAreaId: (state, { payload }: PayloadAction<GetBusinessesParams['businessDataAreaId']>) => {
      state.businessDataAreaId = payload;
    },
    setIncludeDivisions: (state, { payload }: PayloadAction<GetBusinessesParams['includeDivisions']>) => {
      state.includeDivisions = payload;
    },
    setIncludeInactiveDivisionData: (state, { payload }: PayloadAction<GetBusinessesParams['includeInactiveDivisionData']>) => {
      state.includeInactiveDivisionData = payload;
    },
    setIncludeDeletedDivisionData: (state, { payload }: PayloadAction<GetBusinessesParams['includeDeletedDivisionData']>) => {
      state.includeDeletedDivisionData = payload;
    },
    setReturnDeletedDivisionsOnly: (state, { payload }: PayloadAction<GetBusinessesParams['returnDeletedDivisionsOnly']>) => {
      state.returnDeletedDivisionsOnly = payload;
    },
    setOrderDivisionByDirection: (state, { payload }: PayloadAction<GetBusinessesParams['orderDivisionByDirection']>) => {
      state.orderDivisionByDirection = payload;
    },
    setOrderDivisionByField: (state, { payload }: PayloadAction<GetBusinessesParams['orderDivisionByField']>) => {
      state.orderDivisionByField = payload;
    },
    setDivisionNameContains: (state, { payload }: PayloadAction<GetBusinessesParams['divisionNameContains']>) => {
      state.divisionNameContains = payload;
    }
  }
});

export const {
  setBusinessDataAreaId,
  setBusinessNameContains,
  setDivisionNameContains,
  setIncludeDeletedData,
  setIncludeDeletedDivisionData,
  setIncludeDivisions,
  setIncludeInactiveData,
  setIncludeInactiveDivisionData,
  setOrderByDirection,
  setOrderDivisionByDirection,
  setOrderDivisionByField,
  setReturnDeletedBusinessesOnly,
  setReturnDeletedDivisionsOnly
} = abbyCadabbyParams.actions;
