import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Col, List, message, Modal, notification, Row, Space, Spin, Tabs, TabsProps, Typography } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import { toRgba, userFormColors } from 'common/styles/colors';
import { Loader } from 'components/atoms/Loader';
import { WimsicalError } from 'components/atoms/WimsicalError';
import { ColoredCard } from 'components/common/ColoredCard';
import { ActionControls } from 'components/molecules/ActionControls';
import { AssignedCustomersList, DivisionFromUser } from 'components/organisms/AssignedCustomersList';
import { AssignedD365CustomersList } from 'components/organisms/AssignedD365CustomersList';
import { FormikProvider, useFormik } from 'formik';
import { SMSPayload } from 'models/Application';
import { Business } from 'models/Business';
import { UpdatedSMSRecipient } from 'models/NotificationType';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetUserQuery } from 'redux/services/cookieMonster/cookieMonsterApi';
import { useCreateSmsRecipientMutation, useDeleteSmsRecipientMutation, useGetSmsRecipientsQuery } from 'redux/services/sherryNetherland/sherryNetherland';
import { setAssignedCustomersByAllTypes } from 'redux/slices/appSlice';
import { ReduxState } from 'redux/store';

export const ViewUsersPage = (): JSX.Element => {
  const { selectedCompanyDataAreaId, assignedCustomersByAllTypes } = useSelector((state: ReduxState) => state.app);
  const dispatch = useDispatch();

  const { id, userId } = useParams();

  const { data: userData, isLoading: isUserLoading, isFetching: isUserFetching, isError, error } = useGetUserQuery(userId as string, { skip: !userId });

  const {
    data: smsRecipients,
    isLoading: isSmsLoading,
    isFetching: isSmsFetching,
    isError: isSmsError,
    error: smsError
  } = useGetSmsRecipientsQuery({ dataAreaId: 'mdsi', userId: userId as string }, { skip: !userId });

  const [createRecipient] = useCreateSmsRecipientMutation();
  const [deleteRecipient] = useDeleteSmsRecipientMutation();

  useEffect(() => {
    if (smsRecipients) {
      dispatch(setAssignedCustomersByAllTypes(smsRecipients.data.map((customer) => ({ erpId: customer.erpCustomer.erpId, triggerName: customer.triggerName, userId: customer.userId }))));
    }
  }, [smsRecipients]);

  const assignedCustomers: DivisionFromUser[] | undefined = userData && userData.app_metadata.businesses.flatMap((business: Business) => business.divisions);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      test: ''
    },
    onSubmit: async (values) => {
      if (!userId) {
        message.error('Customer cannot be found, the team has been notified');

        return;
      }
      try {
        const getAddedItems = (): SMSPayload[] => {
          return assignedCustomersByAllTypes.filter((copiedItem) => {
            return !smsRecipients?.data.some((originalItem) => originalItem.erpCustomer.erpId === copiedItem.erpId && originalItem.triggerName === copiedItem.triggerName);
          });
        };

        const getRemovedItems = (): UpdatedSMSRecipient[] | undefined => {
          return smsRecipients?.data.filter((originalItem) => {
            return !assignedCustomersByAllTypes.some((copiedItem) => copiedItem.erpId === originalItem.erpCustomer.erpId && copiedItem.triggerName === originalItem.triggerName);
          });
        };

        const addedItems = getAddedItems();
        const removedItems = getRemovedItems();

        let createErrs: any[] = [];
        let deleteErrs: any[] = [];

        if (addedItems.length) {
          const promises = addedItems.map((customer) => {
            return createRecipient({ dataAreaId: 'mdsi', customerErpId: customer.erpId, payload: { userId, triggerName: customer.triggerName } }).unwrap();
          });

          const responses = await Promise.allSettled(promises);

          createErrs = responses.filter((result) => result.status === 'rejected').map((result) => (result as PromiseRejectedResult).reason);
        }
        if (removedItems?.length) {
          const promises = removedItems.map((customer) => {
            const routeId = customer.erpCustomer.erpId === customer.partitionKey ? customer.partitionKey.toUpperCase() : customer.partitionKey;

            return deleteRecipient({ dataAreaId: 'mdsi', customerErpId: routeId, recipientId: customer.id }).unwrap();
          });

          const responses = await Promise.allSettled(promises);

          deleteErrs = responses.filter((result) => result.status === 'rejected').map((result) => (result as PromiseRejectedResult).reason);
        }
        (createErrs || deleteErrs)?.length &&
          Modal.error({
            title: 'Errors',
            width: 700,
            onOk: () => {
              deleteErrs = [];
              createErrs = [];
            },
            content: (
              <Tabs>
                {createErrs?.length && (
                  <TabPane tab="Create Errors" key="1">
                    <List
                      style={{ maxHeight: 500, overflowY: 'auto' }}
                      dataSource={createErrs}
                      renderItem={(item) => (
                        <List.Item>
                          <Typography.Text>{item.data.errorMessage}</Typography.Text>
                        </List.Item>
                      )}
                    />
                  </TabPane>
                )}
                {deleteErrs?.length && (
                  <TabPane tab="Delete Errors" key="2">
                    <List
                      style={{ maxHeight: 500, overflowY: 'auto' }}
                      dataSource={deleteErrs}
                      renderItem={(item) => (
                        <List.Item>
                          <Typography.Text>{item.data.errorMessage}</Typography.Text>
                        </List.Item>
                      )}
                    />
                  </TabPane>
                )}
              </Tabs>
            )
          });

        !(createErrs || deleteErrs)?.length && notification.success({ message: `Configuration updated successfully` });
      } catch (err) {
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
        console.log(err);
      }
    }
  });

  if (isUserLoading || isSmsLoading) return <Loader message="Loading User Notification Configuration..." />;

  if (isError || (isSmsError && ((error ?? smsError) as FetchBaseQueryError).status !== 404)) {
    const queryError = (error ?? smsError) as FetchBaseQueryError;

    return (
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={24}>
          <WimsicalError status={typeof queryError.status === 'number' ? queryError.status : 500} message={''} />
        </Col>
      </Row>
    );
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'SO Verification Notifications',
      children: <AssignedCustomersList trigger="SalesOrderConfirmation" assignedCustomers={assignedCustomers ?? []} />,
      disabled: !userData?.user_metadata.enableStandardOrderVerificationSMSNotifications
    },
    {
      key: '2',
      label: 'SO Shipment Notifications',
      disabled: !userData?.user_metadata.enableStandardOrderShipmentSMSNotifications,
      children: <AssignedCustomersList trigger="Shipments" assignedCustomers={assignedCustomers ?? []} />
    },
    {
      key: '3',
      label: 'Sparing Notifications',
      disabled: !userData?.user_metadata.enableSparingSMSNotifications,
      children: <AssignedCustomersList trigger="SparingOrders" assignedCustomers={assignedCustomers ?? []} />
    }
  ];
  const itemsD365: TabsProps['items'] = [
    {
      key: '1',
      label: 'SO Verification Notifications',
      children: <AssignedD365CustomersList trigger="SalesOrderConfirmation" />,
      disabled: !userData?.user_metadata.enableStandardOrderVerificationSMSNotifications
    },
    {
      key: '2',
      label: 'SO Shipment Notifications',
      disabled: !userData?.user_metadata.enableStandardOrderShipmentSMSNotifications,
      children: <AssignedD365CustomersList trigger="Shipments" />
    },
    {
      key: '3',
      label: 'Sparing Notifications',
      disabled: !userData?.user_metadata.enableSparingSMSNotifications,
      children: <AssignedD365CustomersList trigger="SparingOrders" />
    }
  ];
  const getActiveKey = (): string => {
    if (userData?.user_metadata.enableStandardOrderVerificationSMSNotifications) return '1';
    if (userData?.user_metadata.enableStandardOrderShipmentSMSNotifications) return '2';
    if (userData?.user_metadata.enableSparingSMSNotifications) return '3';

    return '1';
  };

  return (
    <FormikProvider value={formik}>
      <Space direction="vertical">
        <Spin spinning={formik.isSubmitting} indicator={<Loader message="Saving notification systems configuration" />}>
          <ActionControls />
          <Row gutter={[20, 20]}>
            <Col sm={24} md={24} lg={12} xl={12} xxl={12}>
              <ColoredCard style={{ height: '100%' }} title={'Customer Sms Notifications'} color={toRgba(userFormColors.illuminatingEmerald, 0.4)}>
                <Tabs defaultActiveKey={getActiveKey()} items={items} />
              </ColoredCard>
            </Col>
            <Col sm={24} md={24} lg={12} xl={12} xxl={12}>
              <ColoredCard style={{ height: '100%' }} title={'D365 Customer SMS Notifications'} color={toRgba(userFormColors.orangeWeb, 0.4)}>
                <Tabs defaultActiveKey={getActiveKey()} items={itemsD365} />
              </ColoredCard>
            </Col>
          </Row>
        </Spin>
      </Space>
    </FormikProvider>
  );
};
