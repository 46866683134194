import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { Col, message, Row, Space, Spin } from 'antd';
import { Loader } from 'components/atoms/Loader';
import { WimsicalError } from 'components/atoms/WimsicalError';
import { ActionControls } from 'components/molecules/ActionControls';
import { SalesOrderConfirmation } from 'components/molecules/SalesOrderConfirmation';
import { SalesOrderShipments } from 'components/molecules/SalesOrderShipments';
import { SparingRequestShipmentNotifications } from 'components/molecules/SparingRequestShipmentNotifications';
import { FormikProvider, useFormik } from 'formik';
import { NotificationPayload } from 'models/NotificationType';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useCreateEmailRecipientMutation,
  useDeleteEmailRecipientMutation,
  useGetNotificationServicesConfigurationQuery,
  useUpsertNotificationServicesConfigurationMutation
} from 'redux/services/sherryNetherland/sherryNetherland';
import { setEmailsToDelete } from 'redux/slices/appSlice';
import { ReduxState } from 'redux/store';

export const ViewPage = (): JSX.Element => {
  const { selectedCompanyDataAreaId, assignedCustomersByAllTypesEmail, emailsToDelete } = useSelector((state: ReduxState) => state.app);

  const { id } = useParams();

  const dispatch = useDispatch();

  const { data, isLoading, isFetching, isError, error } = useGetNotificationServicesConfigurationQuery(
    { customerErpId: id as string, dataAreaId: selectedCompanyDataAreaId as string },
    { skip: !id || !selectedCompanyDataAreaId }
  );

  const [createRecipient] = useCreateEmailRecipientMutation();
  const [deleteRecipient] = useDeleteEmailRecipientMutation();

  const [upsertNotificationConfiguration] = useUpsertNotificationServicesConfigurationMutation();
  const formik = useFormik<NotificationPayload>({
    enableReinitialize: true,
    initialValues: {
      triggers: data?.triggers ?? [],
      attention: data?.attention ?? ''
    },
    onSubmit: async (values) => {
      if (!id || !selectedCompanyDataAreaId) {
        message.error('Customer cannot be found, the team has been notified');

        return;
      }
      try {
        const upsertResponse = await upsertNotificationConfiguration({ customerErpId: id, dataAreaId: selectedCompanyDataAreaId, payload: { ...formik.values, ...values } }).unwrap();

        if (assignedCustomersByAllTypesEmail.length) {
          const promises = assignedCustomersByAllTypesEmail.map((email) => {
            return createRecipient({ dataAreaId: 'mdsi', customerErpId: id, payload: { description: email.description, emailAddress: email.emailAddress, triggerName: email.triggerName } }).unwrap();
          });

          try {
            const responses = await Promise.all(promises);

            console.log(responses);
          } catch (error) {
            console.error(error);
          }
        }
        if (emailsToDelete.length) {
          const promises = emailsToDelete.map((email) => {
            return deleteRecipient({ dataAreaId: 'mdsi', customerErpId: id, recipientId: email.id }).unwrap();
          });

          try {
            const responses = await Promise.all(promises);

            dispatch(setEmailsToDelete([]));
            console.log(responses);
          } catch (error) {
            console.error(error);
          }
        }

        message.success(`Customer ${upsertResponse.erpCustomer.erpId} successully updated`);
      } catch (err) {
        message.error((err as { data: { errorMessage: string } }).data.errorMessage);
        console.log(err);
      }
    }
  });

  if (isLoading || isFetching) return <Loader />;

  if (isError && (error as FetchBaseQueryError).status !== 404) {
    const queryError = error as FetchBaseQueryError;

    return (
      <Row justify="center" style={{ width: '100%' }}>
        <Col span={24}>
          <WimsicalError status={typeof queryError.status === 'number' ? queryError.status : 500} message={''} />
        </Col>
      </Row>
    );
  }

  return (
    <FormikProvider value={formik}>
      <Space direction="vertical">
        <Spin spinning={formik.isSubmitting} indicator={<Loader message="Saving notification systems configuration" />}>
          <ActionControls />
          <Row>
            <SalesOrderShipments />
          </Row>
          <Row>
            <SalesOrderConfirmation />
          </Row>
          <Row>
            <SparingRequestShipmentNotifications />
          </Row>
        </Spin>
      </Space>
    </FormikProvider>
  );
};
