import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReleasedProductsParams } from 'models/ReleasedProduct';

const initialState: ReleasedProductsParams = {
  offset: 0,
  productNumber: undefined,
  searchName: undefined,
  productGroup: undefined,
  dataAreaId: undefined,
  reservationHierarchy: undefined,
  itemModelGroup: undefined,
  manufacturerId: undefined,
  includeStoppedProducts: undefined,
  returnStoppedProductsOnly: undefined,
  orderByDirection: 'ascending',
  orderByField: 'productNumber',
  response: undefined
};

export const donaldGrumpReleasedProductsParams = createSlice({
  name: 'donaldGrumpReleasedProductsParams',
  initialState,
  reducers: {
    setOffset: (state, { payload }: PayloadAction<ReleasedProductsParams['offset']>) => {
      state.offset = payload;
    },
    incrementOffset: (state) => {
      state.offset += 25;
    },
    setProductNumber: (state, { payload }: PayloadAction<ReleasedProductsParams['productNumber']>) => {
      state.productNumber = payload;
      state.offset = 0;
    },
    setSearchName: (state, { payload }: PayloadAction<ReleasedProductsParams['searchName']>) => {
      state.searchName = payload;
      state.offset = 0;
    },
    setManufacturerId: (state, { payload }: PayloadAction<ReleasedProductsParams['manufacturerId']>) => {
      state.manufacturerId = payload;
      state.offset = 0;
    },
    setResponse: (state, { payload }: PayloadAction<ReleasedProductsParams['response']>) => {
      state.response = payload;
      state.offset = 0;
    },
    setProductGroup: (state, { payload }: PayloadAction<ReleasedProductsParams['productGroup']>) => {
      state.productGroup = payload;
      state.offset = 0;
    },
    setDataAreaId: (state, { payload }: PayloadAction<ReleasedProductsParams['dataAreaId']>) => {
      state.dataAreaId = payload;
      state.offset = 0;
    },
    setReservationHierarchy: (state, { payload }: PayloadAction<ReleasedProductsParams['reservationHierarchy']>) => {
      state.reservationHierarchy = payload;
      state.offset = 0;
    },
    setItemModelGroup: (state, { payload }: PayloadAction<ReleasedProductsParams['itemModelGroup']>) => {
      state.itemModelGroup = payload;
      state.offset = 0;
    },
    setIncludeStoppedProducts: (state, { payload }: PayloadAction<ReleasedProductsParams['includeStoppedProducts']>) => {
      state.includeStoppedProducts = payload;
      state.offset = 0;
    },
    setReturnStoppedProductsOnly: (state, { payload }: PayloadAction<ReleasedProductsParams['returnStoppedProductsOnly']>) => {
      state.returnStoppedProductsOnly = payload;
      state.offset = 0;
    },
    setOrderByDirection: (state, { payload }: PayloadAction<ReleasedProductsParams['orderByDirection']>) => {
      state.orderByDirection = payload;
      state.offset = 0;
    },
    setOrderByField: (state, { payload }: PayloadAction<ReleasedProductsParams['orderByField']>) => {
      state.orderByField = payload;
      state.offset = 0;
    },
    resetParams: (state, { payload }: PayloadAction<ReleasedProductsParams['dataAreaId']>) => {
      Object.assign(state, initialState);
      state.dataAreaId = payload;
    }
  }
});

export const {
  setOffset,
  setOrderByDirection,
  setOrderByField,
  setProductNumber,
  incrementOffset,
  setDataAreaId,
  setIncludeStoppedProducts,
  setItemModelGroup,
  setProductGroup,
  setReservationHierarchy,
  setReturnStoppedProductsOnly,
  setSearchName,
  resetParams,
  setManufacturerId,
  setResponse
} = donaldGrumpReleasedProductsParams.actions;
