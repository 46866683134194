import * as yup from 'yup';
import { Business } from './Business';
import { DivisionReference } from './DeploymentRequest';

export interface Product {
  alternateItem: null | { id: string };
  business: Business;
  categoryName: string;
  createdByFullName: string;
  createdByUserId: string;
  createdDateTime: string;
  division: DivisionReference;
  documentType: string;
  hasSubstitutions: boolean;
  id: string;
  isActive: boolean;
  isDeleted: boolean;
  isItemConfigurable: boolean;
  isKit: boolean;
  manufacturerName: string;
  modifiedByFullName: string;
  modifiedByUserId: string;
  modifiedDateTime: string;
  partitionKey: string;
  partitionKeyDescription: string;
  productDescription: string;
  productName: string;
  productNumber: string;
  searchName: string;
  standardCost: number;
}
export interface ProductPayload {
  alternateItemId?: string | null;
  id: string;
  categoryName: string;
  manufacturerName: string;
  productDescription: string;
  productName: string;
  productNumber: string;
  quantity: number;
  standardCost: number;
}

export enum ProductLabels {
  categoryName = 'Category',
  manufacturerName = 'Manufacturer',
  productDescription = 'Product Description',
  productName = 'Product Name',
  productNumber = 'Product Number',
  quantity = 'Quantity',
  standardCost = 'Standard Cost'
}

export const productSchema: yup.SchemaOf<ProductPayload> = yup.object({
  alternateItemId: yup.string().nullable(),
  id: yup.string().required(),
  categoryName: yup.string().required(),
  manufacturerName: yup.string().required(),
  productDescription: yup.string().required(),
  productName: yup.string().required(),
  productNumber: yup.string().required(),
  quantity: yup.number().required(),
  standardCost: yup.number().required()
});

export interface GetProductsResponse {
  totalCount: number;
  data: Product[];
}
