import { Col, Row, Space } from 'antd';
import Table, { ColumnType } from 'antd/lib/table';
import { toRgba, userFormColors } from 'common/styles/colors';
import { DeleteEmailButton } from 'components/atoms/DeleteEmailButton';
import { Loader } from 'components/atoms/Loader';
import { ColoredCard } from 'components/common/ColoredCard';
import { EmailRecipient } from 'models/NotificationType';
import { FC } from 'react';
import deleteLottie from '../../common/assets/delete-lottie.json';

type Props = {
  listSource?: EmailRecipient[];
  isLoading: boolean;
};

export const EmailNotificationsList: FC<Props> = ({ listSource, isLoading }) => {
  const columns: ColumnType<EmailRecipient>[] = [
    {
      title: 'Email Address',
      dataIndex: 'emailAddress',
      key: 'emailAddress'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Actions',
      align: 'center',
      render: (value, record: EmailRecipient, index): JSX.Element => (
        <Space>
          <DeleteEmailButton animationData={deleteLottie} index={index} contact={record} triggerName={record?.triggerName} />
        </Space>
      )
    }
  ];

  return (
    <ColoredCard title="Contacts (Email)" color={toRgba(userFormColors.robinEggBlue, 0.4)}>
      <Row>
        <Col span={24}>
          <Table pagination={{ defaultPageSize: 5 }} loading={{ spinning: isLoading, indicator: <Loader message="Loading..." /> }} dataSource={listSource ?? []} columns={columns} size="small" />
        </Col>
      </Row>
    </ColoredCard>
  );
};
