import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  filterCustomerId?: string;
  filterCustomerName?: string;
  offset: number;
}
const initialState: InitialState = {
  offset: 0
};

export const customerSelectionFiltersSlice = createSlice({
  name: 'customerSelectionFilters',
  initialState,
  reducers: {
    setFilterCustomerId(state, action: PayloadAction<InitialState['filterCustomerId']>) {
      state.filterCustomerId = action.payload;
      state.offset = 0;
    },
    setFilterCustomerName(state, action: PayloadAction<InitialState['filterCustomerName']>) {
      state.filterCustomerName = action.payload;
      state.offset = 0;
    },
    setCustomerSelectionOffset: (state, { payload }: PayloadAction<InitialState['offset']>) => {
      state.offset = payload;
    }
  }
});

export const { setFilterCustomerId, setFilterCustomerName, setCustomerSelectionOffset } = customerSelectionFiltersSlice.actions;
