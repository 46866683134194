import { SettingOutlined } from '@ant-design/icons';
import { Button, Col, List, Modal, Row, Space, Switch, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { CaretSpinIcon } from 'components/atoms/CaretSpinIcon';
import { ContactCard } from 'components/atoms/ContactCard';
import { EmailNotificationsList } from 'components/organisms/EmailNotificationsList';
import { SmsNotificationsList } from 'components/organisms/SmsNotificationsList';
import { useField, useFormikContext } from 'formik';
import { ContactModel, NotificationPayload, TriggerModel } from 'models/NotificationType';
import { LegacyRef, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { useMeasure } from 'react-use';
import { useGetEmailRecipientByCustomerQuery, useGetSmsRecipientByCustomerQuery } from 'redux/services/sherryNetherland/sherryNetherland';
import { setAssignedCustomersByAllTypesEmail } from 'redux/slices/appSlice';
import { ReduxState } from 'redux/store';
import { AddContactModal } from './AddContactModal';

export const SparingRequestShipmentNotifications: React.FC = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const [showRequestTypes, setShowRequestTypes] = useState(false);
  const { values, setValues } = useFormikContext<NotificationPayload>();
  const [{ value: shipmentValue }, , { setValue }] = useField<TriggerModel>(`triggers[${values.triggers.findIndex((trigger) => trigger.triggerName?.toLocaleLowerCase() === 'sparingorders')}]`);
  const { selectedCompanyDataAreaId, assignedCustomersByAllTypesEmail } = useSelector((state: ReduxState) => state.app);
  const dispatch = useDispatch();

  const listSource = values.triggers.find((trigger) => trigger.triggerName?.toLowerCase() === 'sparingorders');

  const listSrc = assignedCustomersByAllTypesEmail.filter((item) => item.triggerName.toLowerCase() === 'sparingorders');

  const { id } = useParams();

  const { data, isLoading, isFetching, isError, error } = useGetSmsRecipientByCustomerQuery(
    { dataAreaId: selectedCompanyDataAreaId.toLowerCase() as string, erpId: id as string, triggerName: 'SparingOrders' },
    { skip: !id || !selectedCompanyDataAreaId }
  );
  const {
    data: emailData,
    isLoading: isEmailLoading,
    isFetching: isEmailFethin,
    isError: isEmailError,
    error: emailError
  } = useGetEmailRecipientByCustomerQuery(
    { dataAreaId: selectedCompanyDataAreaId.toLowerCase() as string, erpId: id as string, triggerName: 'SparingOrders' },
    { skip: !id || !selectedCompanyDataAreaId }
  );

  useEffect(() => {
    if (emailData?.data) {
      dispatch(setAssignedCustomersByAllTypesEmail([...assignedCustomersByAllTypesEmail, ...emailData.data]));
    }
  }, [emailData]);

  const handleToggleShowRequestTypes = (ev: MouseEvent<HTMLElement>): void => {
    ev.preventDefault();
    ev.stopPropagation();
    setShowRequestTypes((prevState) => !prevState);
  };

  const handleClick = (ev: MouseEvent<HTMLElement, globalThis.MouseEvent>): void => {
    ev.stopPropagation();
    setIsOpen(true);
  };
  const [ref, { height }] = useMeasure();

  const expandAnimation = useSpring({
    config: { friction: 12 }
  });

  const businessListJSX: JSX.Element = (
    <Row>
      <Col span={24}>
        <List
          dataSource={listSource?.emailRecipients}
          loading={{ indicator: <BlockLoader direction="loader loader--slideUp" />, spinning: false }}
          grid={{ gutter: 4, column: 4, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
          style={{ width: '100%' }}
          renderItem={(item: ContactModel, index: number): React.ReactNode => (
            <Col>
              <ContactCard triggerName="SparingOrders" contact={item} index={index} />
            </Col>
          )}
        />
      </Col>
    </Row>
  );

  return (
    <>
      <Modal
        onCancel={(ev): void => {
          ev.stopPropagation();
          setIsSettingsOpen(false);
        }}
        okText="Exit"
        onOk={(ev): void => {
          ev.stopPropagation();
          setIsSettingsOpen(false);
        }}
        cancelButtonProps={{ style: { display: 'none' } }}
        title="Settings"
        open={isSettingsOpen}>
        <Space direction="vertical" size={25}>
          <Col>
            <Space direction="vertical">
              <Typography.Text>Auto send email to buyer</Typography.Text>
              <Switch
                onChange={(ev): void => {
                  if (shipmentValue) setValue({ ...shipmentValue, autoSendEmailToBuyer: ev });
                  else setValues({ ...values, triggers: [...values.triggers, { triggerName: 'SparingOrders', autoSendEmailToBuyer: ev, emailRecipients: [] }] });
                }}
                checked={shipmentValue?.autoSendEmailToBuyer}
              />
            </Space>
          </Col>
          <Col>
            <Space direction="vertical">
              <Typography.Text>Auto send email to sales person</Typography.Text>

              <Switch
                onChange={(ev): void => {
                  if (shipmentValue) setValue({ ...shipmentValue, autoSendEmailToSalesPerson: ev });
                  else setValues({ ...values, triggers: [...values.triggers, { triggerName: 'SparingOrders', autoSendEmailToSalesPerson: ev, emailRecipients: [] }] });
                }}
                checked={shipmentValue?.autoSendEmailToSalesPerson}
              />
            </Space>
          </Col>
          <Col>
            <Space direction="vertical">
              <Typography.Text>Auto send email to sales support</Typography.Text>

              <Switch
                onChange={(ev): void => {
                  if (shipmentValue) setValue({ ...shipmentValue, autoSendEmailToSalesSupport: ev });
                  else setValues({ ...values, triggers: [...values.triggers, { triggerName: 'SparingOrders', autoSendEmailToSalesSupport: ev, emailRecipients: [] }] });
                }}
                checked={shipmentValue?.autoSendEmailToSalesSupport}
              />
            </Space>
          </Col>
        </Space>
      </Modal>
      <AddContactModal triggerName="SparingOrders" listSource={listSrc} isOpen={isOpen} setIsOpen={setIsOpen} />
      <Col span={24} style={{ marginBottom: showRequestTypes ? 10 : 0 }}>
        <Row onClick={handleToggleShowRequestTypes} style={{ padding: 15, background: 'rgba(24, 167, 153, 0.518)', borderRadius: 5, cursor: 'pointer' }} justify="space-between">
          <Typography.Title style={{ fontWeight: 400, padding: 0, margin: 0, fontSize: 22 }} level={3}>
            Sparing Order Notifications
          </Typography.Title>
          <Row gutter={[5, 5]}>
            <Col>
              <Button
                icon={<SettingOutlined />}
                onClick={(ev): void => {
                  ev.stopPropagation();
                  setIsSettingsOpen(true);
                }}
              />
            </Col>
            <Col>
              <Button onClick={handleClick} type="primary">
                Add Email
              </Button>
            </Col>
            <Col>
              <Button style={{ width: 115 }} type="text" onClick={handleToggleShowRequestTypes} key="show-divisions" icon={<CaretSpinIcon isOpen={showRequestTypes} />}>
                {showRequestTypes ? 'Collapse' : 'Expand'}
              </Button>
            </Col>
          </Row>
        </Row>
        <Row style={{ background: showRequestTypes ? 'white' : 'transparent', padding: '0 1.25px' }}>
          <animated.div style={{ ...{ minHeight: 10, overflow: 'hidden', width: '100%' }, ...expandAnimation }}>
            {showRequestTypes && (
              <div ref={ref as LegacyRef<HTMLDivElement>} style={{ width: '100%', padding: 20 }}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <SmsNotificationsList isLoading={isLoading || isFetching} listSource={data?.data} />
                  </Col>
                  <Col span={12}>
                    <EmailNotificationsList isLoading={isEmailLoading || isEmailFethin} listSource={listSrc} />
                  </Col>
                </Row>
              </div>
            )}
          </animated.div>
        </Row>
      </Col>
    </>
  );
};
