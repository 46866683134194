import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnimalsOwnersParams } from 'models/AnimalsOwner';

const initialState: AnimalsOwnersParams = {
  offset: 0,
  ownerNames: undefined,
  ownerNamesContains: undefined,
  overrideSkipTake: true
};

export const animalsOwnersParams = createSlice({
  name: 'animalsOwnersParams',
  initialState,
  reducers: {
    setOwnersOffset: (state, { payload }: PayloadAction<AnimalsOwnersParams['offset']>) => {
      state.offset = payload;
    },
    incrementOwnersOffset: (state) => {
      state.offset += 25;
    },
    setOwnerNames: (state, { payload }: PayloadAction<AnimalsOwnersParams['ownerNames']>) => {
      state.ownerNames = payload;
    },
    setOwnerNamesContains: (state, { payload }: PayloadAction<AnimalsOwnersParams['ownerNamesContains']>) => {
      state.ownerNamesContains = payload;
    }
  }
});

export const { setOwnerNames, setOwnerNamesContains, setOwnersOffset, incrementOwnersOffset } = animalsOwnersParams.actions;
