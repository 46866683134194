import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnimalsConditionsParams } from 'models/AnimalsCondition';

const initialState: AnimalsConditionsParams = {
  offset: 0,
  conditionNameContains: undefined,
  conditionNames: undefined,
  overrideSkipTake: true
};

export const animalsConditionsParams = createSlice({
  name: 'animalsConditionsParams',
  initialState,
  reducers: {
    setConditionsOffset: (state, { payload }: PayloadAction<AnimalsConditionsParams['offset']>) => {
      state.offset = payload;
    },
    incrementConditionsOffset: (state) => {
      state.offset += 25;
    },
    setConditionNameContains: (state, { payload }: PayloadAction<AnimalsConditionsParams['conditionNameContains']>) => {
      state.conditionNameContains = payload;
    },
    setConditionNames: (state, { payload }: PayloadAction<AnimalsConditionsParams['conditionNames']>) => {
      state.conditionNames = payload;
    }
  }
});

export const { setConditionNameContains, setConditionNames, setConditionsOffset, incrementConditionsOffset } = animalsConditionsParams.actions;
