import { List } from 'antd';
import { toRgba, userFormColors } from 'common/styles/colors';
import { SizedLoader } from 'components/atoms/SizedLoader';
import { SmsListItem } from 'components/atoms/SmsListItem';
import { ColoredCard } from 'components/common/ColoredCard';
import { UpdatedSMSRecipient } from 'models/NotificationType';
import { FC } from 'react';
type Props = {
  listSource?: UpdatedSMSRecipient[];
  isLoading: boolean;
};

export const SmsNotificationsList: FC<Props> = ({ listSource, isLoading }) => {
  return (
    <ColoredCard title="Contacts (SMS Opt In)" color={toRgba(userFormColors.illuminatingEmerald, 0.4)}>
      <List
        loading={{ spinning: isLoading, indicator: <SizedLoader medium /> }}
        grid={{ column: 3, gutter: 8 }}
        size="small"
        dataSource={listSource ?? []}
        renderItem={(item) => <SmsListItem item={item} />}
      />
    </ColoredCard>
  );
};
