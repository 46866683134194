import { Space } from 'antd';
import { ChangeEvent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setNameContains } from 'redux/services/cookieMonster/userParams';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from '../FancyInput';

export const UserNameContainsFilter = (): JSX.Element => {
  const dispatch = useDispatch();

  /* ****************** Functions ****************** */
  // Handle delay of searching data
  const handleInputChange = useDebouncedCallback((ev: ChangeEvent<HTMLInputElement>) => {
    if (ev.target.value === '') {
      dispatch(setNameContains(undefined));

      return;
    }
    dispatch(setNameContains(ev.target.value));
  }, 500);

  useEffect(() => {
    return () => {
      dispatch(setNameContains(undefined));
    };
  }, []);

  /* ****************** Render ****************** */

  return (
    <Space direction="vertical" size="small" style={{ marginLeft: 5, marginRight: 5 }}>
      <FancyInput placeholder="Name" onChange={handleInputChange} width={150} />
    </Space>
  );
};
