import { Button, ButtonProps, Modal } from 'antd';
import { EmailRecipient } from 'models/NotificationType';
import { FC, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useDispatch } from 'react-redux';
import { addEmailToDelete, deleteAssignedCustomersByAllTypesEmail } from 'redux/slices/appSlice';

type Props = ButtonProps & {
  contact: EmailRecipient;
  index: number;
  triggerName?: string;
  animationData: object;
};

export const DeleteEmailButton: FC<Props> = ({ contact, triggerName, animationData, ...rest }) => {
  const [hover, setHover] = useState(false);

  const dispatch = useDispatch();

  const handleDelete = (): void => {
    Modal.confirm({
      title: 'Are you sure you want to delete this contact? Changes will not go into effect until you save',
      onOk: () => {
        dispatch(addEmailToDelete(contact));
        dispatch(deleteAssignedCustomersByAllTypesEmail(contact));
      }
    });
  };

  return (
    <Button
      onMouseEnter={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)}
      icon={<Lottie animationData={animationData} loop={false} goTo={!hover ? 0 : undefined} play={hover} style={{ width: 25, height: 25, display: 'inline-block', transform: 'scale(2.1)' }} />}
      type="default"
      onClick={handleDelete}
      {...rest}
    />
  );
};
