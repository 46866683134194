import { Col, Form, message, Modal, Space, Typography } from 'antd';
import { MessageType } from 'antd/lib/message';
import { TextInput } from 'components/UI/TextInput';
import { FormikProvider, useField, useFormik, useFormikContext } from 'formik';
import { EmailPayload } from 'models/Application';
import { EmailRecipient, NotificationPayload, TriggerModel } from 'models/NotificationType';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { addAssignedCustomersByAllTypesEmail } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';
import * as yup from 'yup';
import { contactPayload } from './EditContactModal';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  triggerName: string;
  listSource?: EmailPayload[];
}

export const test: yup.SchemaOf<{ email: string | undefined }> = yup.object({
  email: yup.string().label('Email Address').email('Please enter a valid email address').required()
});
export const AddContactModal: FC<Props> = ({ isOpen, setIsOpen, triggerName, listSource }) => {
  const dispatch = useDispatch();
  const { values: formValues, setValues } = useFormikContext<NotificationPayload>();
  const [{ value: triggerVal }, b, { setValue: setTriggerContacts }] = useField<TriggerModel>(
    `triggers[${formValues.triggers.findIndex((trigger) => trigger.triggerName?.toLocaleLowerCase() === triggerName.toLocaleLowerCase())}]`
  );

  const { assignedCustomersByAllTypesEmail } = useAppSelector((state) => state.app);
  const addFormik = useFormik<Partial<EmailRecipient>>({
    enableReinitialize: true,
    initialValues: {
      emailAddress: '',
      description: '',
      triggerName: triggerName
    },
    validationSchema: contactPayload,
    onSubmit: async (values): Promise<void | MessageType> => {
      let error;

      if (
        listSource?.some((shipVal) => {
          if (shipVal.description === values.description) {
            error = 'Contact already exists in list';

            return true;
          }
          if (shipVal.emailAddress === values.emailAddress) {
            error = 'Email already exists in list';

            return true;
          }
        })
      )
        return message.error(error);

      console.log('values', values);
      dispatch(addAssignedCustomersByAllTypesEmail(values as EmailRecipient));

      message.success('Contact added');
      setIsOpen(false);
      addFormik.resetForm();
    }
  });

  const handleCancel = (): void => {
    setIsOpen(false);
    addFormik.resetForm();
  };

  return (
    <>
      <Modal okText="Submit" onOk={(): Promise<void> => addFormik.submitForm()} closable={false} onCancel={handleCancel} open={isOpen}>
        <FormikProvider value={addFormik}>
          <Col span={24} style={{ background: '#18a79984', borderRadius: 10, marginBottom: 10 }}>
            <Typography.Title level={4} style={{ fontWeight: 400, fontSize: 20, marginBottom: 0, padding: 10, marginLeft: 10 }}>
              Contact Info
            </Typography.Title>
          </Col>
          <Col style={{ margin: '20px 10px' }}>
            <Form layout="vertical">
              <Space style={{ width: '100%' }} direction="vertical" size={12}>
                <TextInput label="Description" fieldName="description" />
                <TextInput label="Email Address" fieldName="emailAddress" />
              </Space>
            </Form>
          </Col>
        </FormikProvider>
      </Modal>
    </>
  );
};
