import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Checkbox, Col, List, Row, Space, Tooltip, Typography } from 'antd';
import { FancyInput } from 'components/atoms/FancyInput';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAssignedCustomersByAllTypes } from 'redux/slices/appSlice';
import { useAppSelector } from 'redux/store';

type Props = {
  assignedCustomers: DivisionFromUser[];
  trigger: string;
};

export type DivisionFromUser = { erpId: string; id: string; name: string };

export const AssignedCustomersList: FC<Props> = ({ assignedCustomers, trigger }) => {
  const { assignedCustomersByAllTypes } = useAppSelector((state) => state.app);
  const dispatch = useDispatch();

  const { user } = useAuth0();
  const userId = user?.sub as string;

  const [searchFilter, setSearchFilter] = useState({
    name: '',
    accountNumber: ''
  });

  const filteredData = (): DivisionFromUser[] => {
    return assignedCustomers.filter((customer) => {
      return customer.name.toLowerCase().includes(searchFilter.name.toLowerCase()) && customer.erpId.toLowerCase().includes(searchFilter.accountNumber.toLowerCase());
    });
  };

  const handleCheck = (checked: boolean, erpId: string, triggerName: string): void => {
    if (checked) {
      dispatch(setAssignedCustomersByAllTypes([...assignedCustomersByAllTypes, { erpId, triggerName, userId }]));
    } else {
      const updatedCustomers = assignedCustomersByAllTypes.filter((customer) => {
        return !(customer.erpId === erpId && customer.triggerName === triggerName);
      });

      dispatch(setAssignedCustomersByAllTypes(updatedCustomers));
    }
  };

  const handleRemoveAll = (): void => {
    const updatedCustomers = assignedCustomersByAllTypes.filter((customer) => {
      return customer.triggerName !== trigger;
    });

    dispatch(setAssignedCustomersByAllTypes(updatedCustomers));
  };

  const handleCheckAll = (): void => {
    dispatch(setAssignedCustomersByAllTypes([...assignedCustomersByAllTypes, ...assignedCustomers.map((customer) => ({ triggerName: trigger, erpId: customer.erpId, userId }))]));
  };

  const handleLabelClick = (erpId: string, triggerName: string): void => {
    if (assignedCustomersByAllTypes.some((customer) => customer.erpId === erpId && customer.triggerName === triggerName)) {
      const updatedCustomers = assignedCustomersByAllTypes.filter((customer) => {
        return !(customer.erpId === erpId && customer.triggerName === triggerName);
      });

      dispatch(setAssignedCustomersByAllTypes(updatedCustomers));
    } else {
      dispatch(setAssignedCustomersByAllTypes([...assignedCustomersByAllTypes, { erpId, triggerName, userId }]));
    }
  };

  return (
    <div style={{ marginTop: 16 }}>
      <Row justify={'space-between'}>
        <Space style={{ marginBottom: 16 }}>
          <FancyInput
            placeholder="Customer Name"
            onChange={(e): void => {
              setSearchFilter({ ...searchFilter, name: e.target.value });
            }}
          />
          <FancyInput
            placeholder={'Customer Account'}
            onChange={(e): void => {
              setSearchFilter({ ...searchFilter, accountNumber: e.target.value });
            }}
          />
        </Space>
        <Space>
          <Tooltip title="Add all customers">
            <Button onClick={handleCheckAll} type="primary" icon={<CheckOutlined />} />
          </Tooltip>
          <Tooltip title="Remove all customers">
            <Button onClick={handleRemoveAll} danger icon={<CloseOutlined />} />
          </Tooltip>
        </Space>
      </Row>

      <List
        style={{ height: 515, overflowY: 'scroll' }}
        dataSource={filteredData()}
        renderItem={(item): JSX.Element => {
          const isChecked = assignedCustomersByAllTypes.some((customer) => customer.erpId === item.erpId && customer.triggerName === trigger);

          return (
            <List.Item>
              <Row gutter={[10, 10]}>
                <Col>
                  <Checkbox checked={isChecked} onChange={(checked) => handleCheck(checked.target.checked, item.erpId, trigger)} />
                </Col>
                <Col>
                  <Typography.Text onClick={() => handleLabelClick(item.erpId, trigger)}>
                    {item.erpId} - {item.name}
                  </Typography.Text>
                </Col>
              </Row>
            </List.Item>
          );
        }}
      />
    </div>
  );
};
