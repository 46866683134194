import { Avatar, List, Spin } from 'antd';
import { SmsRecipient } from 'models/NotificationType';
import { FC } from 'react';
import { useGetUserQuery } from 'redux/services/cookieMonster/cookieMonsterApi';

type Props = {
  item: SmsRecipient;
};

export const SmsListItem: FC<Props> = ({ item }) => {
  const { data, isLoading, isFetching } = useGetUserQuery(item.userId);
  const initials = item.fullName
    .split(' ')
    .map((n) => n[0])
    .join('');

  return (
    <List.Item style={{ border: '1px solid lightgray', padding: 8, borderRadius: 10 }}>
      <List.Item.Meta
        avatar={
          <Spin spinning={isLoading}>
            <Avatar src={data?.user_metadata.profile_image_url}>{initials}</Avatar>
          </Spin>
        }
        title={item.fullName}
        description={item.cellPhoneNumber}
      />
    </List.Item>
  );
};
