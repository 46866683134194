import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnimalsDispositionsParams } from 'models/AnimalsDisposition';

const initialState: AnimalsDispositionsParams = {
  offset: 0,
  dispositionNameContains: undefined,
  dispositionNames: undefined,
  overrideSkipTake: true
};

export const animalsDispositionsParams = createSlice({
  name: 'animalsDispositionsParams',
  initialState,
  reducers: {
    setDispositionsOffset: (state, { payload }: PayloadAction<AnimalsDispositionsParams['offset']>) => {
      state.offset = payload;
    },
    incrementDispositionsOffset: (state) => {
      state.offset += 25;
    },
    setDispositionNameContains: (state, { payload }: PayloadAction<AnimalsDispositionsParams['dispositionNameContains']>) => {
      state.dispositionNameContains = payload;
    },
    setDispositionsNames: (state, { payload }: PayloadAction<AnimalsDispositionsParams['dispositionNames']>) => {
      state.dispositionNames = payload;
    }
  }
});
