import { EllipsisOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, MenuProps, Row, Space, Typography } from 'antd';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const ActionControls: FC = () => {
  const { submitForm } = useFormikContext();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const items: MenuProps['items'] = [
    {
      key: 'save',
      label: <Typography.Text>Save</Typography.Text>,
      onClick: async (): Promise<void> => await submitForm()
    }
  ];

  const handleSubmitAndExit = async (): Promise<void> => {
    await submitForm();
    navigate('/');
  };

  return (
    <Row align="middle" justify="space-between" style={{ width: '100%', paddingBottom: 16, paddingTop: 5 }}>
      <Row gutter={[10, 10]} align="middle">
        <Col>
          <Typography.Title style={{ fontWeight: '400', margin: 0, padding: 0 }} level={3}>
            Notification Services Configuration
          </Typography.Title>
        </Col>
      </Row>
      <Col>
        <Space>
          <Button onClick={(): void => navigate(pathname.includes('users') ? '/users' : '/')}>Back</Button>
          <Dropdown.Button onClick={handleSubmitAndExit} menu={{ items }} type="primary" icon={<EllipsisOutlined />}>
            Save and Exit
          </Dropdown.Button>
        </Space>
      </Col>
    </Row>
  );
};
