import { Typography } from 'antd';
import { TitleProps } from 'antd/lib/typography/Title';
import { InlineStylesModel } from 'models/InlineStylesModel';
import React from 'react';

const styles: InlineStylesModel = {
  formLabel: {
    marginBottom: 0
  },
  formLabelSwitch: {
    marginBottom: 0
  }
};

interface Props extends TitleProps {
  label: string;
  isSwitch?: boolean;
}

export const FormLabel: React.FC<Props> = ({ label, isSwitch, ...rest }) => {
  return (
    <Typography.Text {...rest} style={isSwitch ? styles.formLabelSwitch : styles.formLabel}>
      {label}
    </Typography.Text>
  );
};
