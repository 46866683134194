import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { setAccessToken, setUser } from 'redux/slices/appSlice';
import { useAppDispatch, useAppSelector } from 'redux/store';

export const useAuthentication = (): { isLoading: boolean; accessToken: string | null } => {
  const { accessToken } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const { isAuthenticated, loginWithRedirect, isLoading, user, getAccessTokenSilently } = useAuth0();

  // Check if User is logged in, if not, redirect them to login screen
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect({ appState: { targetUrl: '/' } });
    }
    if (isAuthenticated && accessToken === null) {
      getAccessTokenSilently().then((accessToken) => dispatch(setAccessToken(accessToken)));
    }
  }, [accessToken, dispatch, getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    }
  }, [dispatch, user]);

  return { isLoading, accessToken };
};
