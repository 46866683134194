import { Card, CardProps, Row, Typography } from 'antd';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { FC } from 'react';

const styles: InlineStylesModel = {
  headerTitle: {
    margin: 0,
    padding: 0,
    fontWeight: 'normal'
  }
};

type Props = CardProps & {
  children: React.ReactNode;
  color: string;
  title: string;
  icon?: React.ReactNode;
};

export const ColoredCard: FC<Props> = ({ children, color, title, icon, ...rest }) => {
  return (
    <Card
      headStyle={{ background: color }}
      title={
        <Row justify="space-between" align="middle">
          <Typography.Title level={4} style={styles.headerTitle}>
            {title}
          </Typography.Title>
          {icon && icon}
        </Row>
      }
      {...rest}>
      {children}
    </Card>
  );
};
