import { About } from 'pages/about/About';
import { EditProfilePage } from 'pages/editProfile/EditProfile';
import { Home } from 'pages/home/Home';
import { ReleaseNotesPage } from 'pages/releaseNotes/ReleaseNotesPage';
import { SearchUsersPage } from 'pages/users/SearchUsersPage';
import { ViewPage } from 'pages/view/ViewPage';
import { ViewUsersPage } from 'pages/view/ViewUsersPage';
import { Route, Routes } from 'react-router-dom';

export const MainRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/users" element={<SearchUsersPage />} />
      <Route path="/about" element={<About />} />
      <Route path="/release-notes/:version" element={<ReleaseNotesPage />} />
      <Route path="/profile" element={<EditProfilePage />} />
      <Route path="/customers/:id" element={<ViewPage />} />
      <Route path="/users/:userId" element={<ViewUsersPage />} />
    </Routes>
  );
};
