import { Avatar, List } from 'antd';
import { Users } from 'models/Users';
import { FC } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  user: Users;
};

export const UserCard: FC<Props> = ({ user }) => {
  const colors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
  // pick random color from array
  const randomColor = colors[Math.floor(Math.random() * colors.length)];

  // take first letter of first and last name
  const initials = user.name
    .split(' ')
    .map((n) => n[0])
    .join('');

  return (
    <List.Item>
      <List.Item.Meta
        avatar={
          <Avatar style={{ backgroundColor: randomColor }} src={user.user_metadata.profile_image_url}>
            {initials}
          </Avatar>
        }
        title={<Link to={`/users/${user.user_id}`}>{user.name}</Link>}
        description={user.email}
      />
    </List.Item>
  );
};
