import { InlineStylesModel } from 'models/InlineStylesModel';

export enum StreetClassNames {
  assignCustomersContainer = 'assign-customers-container',
  assignCustomersBusinessContainer = 'assign-customers-business-container',
  businessTile = 'business-tile',
  businessRow = 'business-row',
  mobileTopDrawer = 'mobile-top-drawer',
  assignRolesTabButtonRow = 'assign-roles-tab-button-row',
  bertContainer = 'beaker-container',
  bertContent = 'beaker-content',
  dropdownLink = 'dropdown-link',
  collapseContainer = 'collapse-container',
  configurationContainer = 'configuration-container',
  customComponentHeader = 'custom-component-header',
  customerCollapse = 'customer-collapse',
  editButton = 'edit-button',
  filterContainer = 'filter-container',
  formContainer = 'form-container',
  newUserButton = 'new-user-button',
  pageHeader = 'page-header',
  permissionsList = 'permissions-list',
  roleContainer = 'role-container',
  robotPointingImage = 'robot-pointing-image',
  submitButtonMenuLi = 'submit-button-menu-li',
  userFormItem = 'user-form-item',
  clickableText = 'clickable-text',
  customMoveAnimation = 'custom-move-animation',
  customCard = 'custom-card',
  customTile = 'custom-tile',
  titleContainer = 'title-container',
  cardButton1 = 'card-button-1',
  cardButton2 = 'card-button-2',
  cardButton3 = 'card-button-3',
  cardButton4 = 'card-button-4',
  searchBar = 'search-bar',
  searchColumn = 'search-column',
  searchSpan = 'search-span',
  streetCollapsePanel = 'street-collapse-panel',
  subNav = 'sub-nav',
  subNavListItem = 'sub-nav-list-item',
  subNavListItemSelected = 'sub-nav-list-item-selected',
  titleButton = 'title-button',
  titleRecoverButton = 'title-recover-button',
  titleSearch = 'title-search',
  userDetailsForm = 'user-details-form',
  userTabs = 'user-tabs',
  streetContent = 'streetContent',
  streetContainer = 'streetContainer',
  activeCollapsePanel = 'active-collapse-panel',
  tileHover = 'tile-hover',
  hideElement = 'hide-element'
}

export const funColors = {
  blue: '#374afb',
  blueBackground: 'rgba(54, 74, 251, 0.1)',
  blueBackgroundInverted: '#374afb',
  blueInverted: '#ffffff',
  pink: '#fd397a',
  pinkBackground: 'rgba(253, 57, 122, 0.1)',
  pinkBackgroundInverted: '#fd397a',
  pinkInverted: '#ffffff',
  turqoiuse: '#1dc9b7',
  turqoiuseBackground: 'rgba(29, 201, 183, 0.1)',
  turqoiuseBackgroundInverted: '#1dc9b7',
  turqoiuseInverted: '#ffffff',
  yellow: '#ffb822',
  yellowBackground: 'rgba(255, 184, 34, 0.1)',
  yellowBackgroundInverted: '#ffb822',
  yellowInverted: '#111111'
};

export const globalStyles: InlineStylesModel = {
  bertContent: {
    display: 'flex',
    fontFamily: 'Poppins',
    justifyContent: 'center',
    width: '100%'
  },
  bertTitle: {
    color: '#434349',
    fontSize: '1rem',
    fontWeight: 500,
    marginBottom: '0px'
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  card: {
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    cursor: 'pointer',
    display: 'flex',
    flexFlow: 'column nowrap',
    font: '13px Poppins',
    textAlign: 'center',
    width: '95%'
  },
  cardButton1: {
    background: funColors.yellowBackground,
    border: 'none',
    color: funColors.yellow,
    cursor: 'pointer',
    flex: '1',
    font: '13x Poppins',
    fontWeight: 900,
    height: 'auto',
    margin: '16px 0px',
    maxWidth: '50px',
    padding: '14.3px 0px',
    transition: '0s',
    width: '50px'
  },
  cardButton2: {
    background: funColors.pinkBackground,
    border: 'none',
    color: funColors.pink,
    cursor: 'pointer',
    filter: 'contrast(3.02)',
    flex: '1',
    font: '13x Poppins',
    fontWeight: 900,
    height: 'auto',
    margin: '16px 0px',
    maxWidth: '50px',
    padding: '14.3px 0px',
    transition: '0s',
    width: '50px'
  },
  cardButton3: {
    background: funColors.blueBackground,
    border: 'none',
    color: funColors.blue,
    cursor: 'pointer',
    flex: '1',
    font: '13x Poppins',
    fontWeight: 900,
    height: 'auto',
    margin: '16px 0px',
    maxWidth: '50px',
    padding: '14.3px 0px',
    transition: '0s',
    width: '50px'
  },
  cardButton4: {
    background: funColors.turqoiuseBackground,
    border: 'none',
    color: funColors.turqoiuse,
    cursor: 'pointer',
    filter: 'contrast(1)',
    flex: '1',
    font: '13x Poppins',
    fontWeight: 900,
    height: 'auto',
    margin: '16px 0px',
    maxWidth: '50px',
    padding: '14.3px 0px',
    transition: '0s',
    width: '50px'
  },
  // cardButtonRow: {
  //   display: 'flex',
  //   justifyContent: 'space-between',
  //   width: '100%',
  // },
  cardContainer: {
    display: 'flex',
    padding: '2px 0px',
    width: '100%'
  },
  cardMeta: {
    width: '100%'
  },
  clickableText: {
    color: '#000000',
    font: '14px Poppins',
    fontWeight: 500,
    opacity: 0.45,
    padding: '0px 3.9px 0px 0px',
    position: 'relative',
    width: 'fit-content'
  },
  recoverButton: {
    background: funColors.turqoiuseBackground,
    border: 'none',
    color: funColors.turqoiuse,
    cursor: 'pointer',
    flex: '1',
    font: '13x Poppins',
    fontWeight: 900,
    transition: '0s'
  },
  streetContent: {
    display: 'flex',
    fontFamily: 'Poppins',
    fontVariant: 'tabular-nums',
    justifyContent: 'center',
    width: '100%'
  },
  streetHeader: {
    alignItems: 'center',
    background: '#1f1e2e',
    color: '#84859a',
    display: 'flex',
    fontFamily: 'Poppins',
    justifyContent: 'center'
  },
  streetWhiteSection: {
    background: 'rgb(255, 255, 255)',
    borderRadius: '4px',
    boxShadow: '0px 0px 13px 0px rgba(82, 63, 105, 0.05)',
    padding: '16px'
  },
  title: {
    color: '#434349',
    font: '1rem Poppins',
    fontWeight: 500,
    marginBottom: '0px',
    padding: '0px 13px 0px 0px'
  },
  titleAddIcon: {
    color: funColors.blue,
    fontSize: '20px',
    margin: '2px 5px'
  },
  titleButton: {
    background: funColors.blueBackground,
    border: 'none',
    color: funColors.blue,
    font: '600 13px Poppins',
    margin: '3.25px 3.25px 3.25px 3.25px'
  },
  titleCol: {
    display: 'flex',
    padding: '0px'
  },
  titleContainer: {
    display: 'flex'
  },
  titleRecoverButton: {
    background: funColors.turqoiuseBackground,
    border: 'none',
    color: funColors.turqoiuse,
    font: '600 13px Poppins',
    margin: '3.25px 3.25px 3.25px 3.25px'
  },
  titleRecoverIcon: {
    color: funColors.turqoiuse,
    fontSize: '13px',
    margin: '2px 5px'
  },
  titleRow: {
    margin: '10px 0px 0px'
  },
  titleSearchContainer: {
    alignItems: 'center',
    display: 'flex',
    padding: '.25rem 0rem'
  },
  topLevelButtons: {
    padding: '0px',
    textAlign: 'right'
  }
};
