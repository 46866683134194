import { Space } from 'antd';
import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { setFilterCustomerName } from 'redux/slices/customerSelectionFiltersSlice';
import { useDebouncedCallback } from 'use-debounce';
import { FancyInput } from '../FancyInput';

export const CustomerNameFilter = (): JSX.Element => {
  const dispatch = useDispatch();

  /* ****************** Functions ****************** */
  // Handle delay of searching data
  const handleInputChange = useDebouncedCallback((ev: ChangeEvent<HTMLInputElement>) => {
    dispatch(setFilterCustomerName(ev.target.value));
  }, 500);

  /* ****************** Render ****************** */

  return (
    <Space direction="vertical" size="small" style={{ marginLeft: 5, marginRight: 5 }}>
      <FancyInput placeholder="Customer Name" onChange={handleInputChange} width={150} />
    </Space>
  );
};
