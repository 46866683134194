import * as yup from 'yup';

export interface Project {
  id: string | null;
  name: string | null;
  customerProjectId: string | null;
  task: Task | null;
}

export interface Task {
  name: string;
  customerTaskId: string;
}

export const projectSchema: yup.SchemaOf<Project> = yup.object({
  id: yup.string().required(),
  name: yup.string().required(),
  customerProjectId: yup.string().required(),
  task: yup.mixed().oneOf([
    yup.object({
      name: yup.string().required(),
      customerTaskId: yup.string().required()
    }),
    null
  ])
});
