import { Checkbox, Col, List, Row, Space, Typography } from 'antd';
import { CustomerIdFilter } from 'components/atoms/filters/CustomerIdFilter';
import { CustomerNameFilter } from 'components/atoms/filters/CustomerNameFilter';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetCustomersQuery } from 'redux/services/beautifulDayMonster/beautifulDayMonsterApi';
import { setAssignedCustomersByAllTypes } from 'redux/slices/appSlice';
import { ReduxState, useAppSelector } from 'redux/store';

type Props = {
  trigger: string;
};

export const AssignedD365CustomersList: FC<Props> = ({ trigger }) => {
  const { assignedCustomersByAllTypes } = useAppSelector((state) => state.app);
  const dispatch = useDispatch();
  const { selectedCompanyDataAreaId } = useSelector((state: ReduxState) => state.app);
  const { filterCustomerId, filterCustomerName, offset } = useSelector((state: ReduxState) => state.customerSelectionFilters);

  /* ****************** API/Hooks ****************** */

  const { data, isFetching, isError, error, isLoading } = useGetCustomersQuery(
    {
      customerAccountContains: filterCustomerId,
      customerNameContains: filterCustomerName,
      orderByField: 'customerAccount',
      offset,
      dataAreaId: selectedCompanyDataAreaId as string
    },
    {
      skip: !selectedCompanyDataAreaId
    }
  );

  const { id, userId } = useParams();

  const handleCheck = (checked: boolean, erpId: string, triggerName: string): void => {
    if (checked) {
      dispatch(setAssignedCustomersByAllTypes([...assignedCustomersByAllTypes, { erpId, triggerName, userId: userId as string }]));
    } else {
      const updatedCustomers = assignedCustomersByAllTypes.filter((customer) => {
        return !(customer.erpId === erpId && customer.triggerName === triggerName);
      });

      dispatch(setAssignedCustomersByAllTypes(updatedCustomers));
    }
  };

  const handleLabelClick = (erpId: string, triggerName: string): void => {
    if (assignedCustomersByAllTypes.some((customer) => customer.erpId === erpId && customer.triggerName === triggerName)) {
      const updatedCustomers = assignedCustomersByAllTypes.filter((customer) => {
        return !(customer.erpId === erpId && customer.triggerName === triggerName);
      });

      dispatch(setAssignedCustomersByAllTypes(updatedCustomers));
    } else {
      dispatch(setAssignedCustomersByAllTypes([...assignedCustomersByAllTypes, { erpId, triggerName, userId: userId as string }]));
    }
  };

  return (
    <div style={{ marginTop: 16 }}>
      <Row justify={'space-between'}>
        <Space style={{ marginBottom: 16 }}>
          <CustomerIdFilter />
          <CustomerNameFilter />
        </Space>
      </Row>

      <List
        style={{ height: 515, overflowY: 'scroll' }}
        dataSource={data?.data || []}
        renderItem={(item): JSX.Element => {
          const isChecked = assignedCustomersByAllTypes.some((customer) => customer.erpId === item.customerAccount && customer.triggerName === trigger);

          return (
            <List.Item>
              <Row gutter={[10, 10]}>
                <Col>
                  <Checkbox checked={isChecked} onChange={(checked) => handleCheck(checked.target.checked, item.customerAccount, trigger)} />
                </Col>
                <Col>
                  <Typography.Text onClick={() => handleLabelClick(item.customerAccount, trigger)}>
                    {item.customerAccount} - {item.name}
                  </Typography.Text>
                </Col>
              </Row>
            </List.Item>
          );
        }}
      />
    </div>
  );
};
